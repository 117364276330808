<template>
  <div class="footer">
    <div class="copy">2022 Data Cloud</div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.footer {
  align-items: center;
  max-width: var(--page_width);
  margin: 20px auto;
  margin-bottom: 40px;
}
.footer * {
  font-size: smaller;
}
.footer .copy {
  margin: 0 8px;
  text-align: end;
}
</style>
