<template>
  <div class="home">
    <div class="header-block">
      <h3>Аудит торговых точек</h3>
    </div>
    <div v-if="!pending">
      <!-- settings -->
      <div class="block">
        <label for="select-territory">Выберите город:</label>
        <select
          name=""
          id="select-territory"
          class="select-territory"
          v-model="selectedTerritoryId"
          @change="handleSelectTerritory"
        >
          <option
            v-for="territory in territories"
            :key="territory"
            :value="territory.id"
          >
            <span v-if="territory.district">
              {{
                `${territory.locality} (${territory.district}, ${territory.province})`
              }}
            </span>
            <span v-else>
              {{ `${territory.locality} (${territory.province})` }}
            </span>
          </option>
        </select>
        <label for="select-form">Выберите тип анкеты:</label>
        <select
          name=""
          id="select-form"
          class="select-form"
          v-model="selectedFormTypeId"
          @change="handleSelectFormType"
        >
          <option
            v-for="formType in formTypes"
            :key="formType"
            :value="formType.id"
          >
            {{ formType.title }}
          </option>
        </select>
        <div class="home-points-nearby" v-if="!activeForm">
          <div class="home-points-nearby-action">
            <button :disabled="pendingPoints" @click="handleSalesPointsNearby">
              Найти точки рядом
            </button>
            <LoaderCircular v-if="pendingPoints" size="34px" />
          </div>
          <div
            class="home-points-nearby-accuracy"
            v-if="gpsAccuracy && !pendingPoints"
          >
            {{ `точность ${Math.round(gpsAccuracy)} м` }}
          </div>
        </div>
      </div>
      <!-- error -->
      <div class="error" v-if="errorMessage">
        {{ errorMessage }}
      </div>
      <!-- active form -->
      <div v-if="activeForm">
        <div class="header-block">
          <h4>Текущая анкета:</h4>
        </div>
        <div class="block">
          <span class="home-point-title">{{ activeForm.title }}</span>
          <div>{{ activeForm.address }}</div>
          <div class="home-point-actions">
            <button @click="handleContinueForm">Продолжить</button>
            <!-- <span class="sep"></span> -->
            <button @click="handleCancelForm">Отменить</button>
          </div>
        </div>
      </div>
      <!-- points list -->
      <div v-if="salesPoints">
        <div class="header-block">
          <h4>Торговые точки рядом:</h4>
        </div>
        <div class="block" v-for="point in salesPoints" :key="point">
          <div>
            <span class="home-point-title">{{ point.title }}</span>
            <span v-if="!point.active" class="home-point-disabled">- не действует</span>
            <span class="point-distance"
              >({{ Math.round(point.distance) }} м)</span
            >
            <span class="point-code-label">Код:</span>
            <span class="point-code">{{point.code}}</span>
          </div>
          <div>{{ point.address }}</div>
          <div class="facade-photo" v-if="point.photoFacadeThumbnail">
            <img :src="point.photoFacadeThumbnail" alt="" />
          </div>
          <div>
            <button @click="handleNewForm(point)">Новая анкета</button>
          </div>
        </div>
        <div class="block">
          <div>ТТ нет в списке</div>
          <div></div>
          <div>
            <button @click="handleNewForm(null)">Новая анкета</button>
          </div>
        </div>
      </div>
    </div>
    <div class="loader-center" v-else>
      <LoaderCircular />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { analytics } from "@/firebase/config";
import useApi from "@/composables/useApi";
import firebaseCredential from "@/composables/firebaseCredential";
import LoaderCircular from "@/components/LoaderCircular";
export default {
  components: {
    LoaderCircular,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const { getFormsTypes, getTerritories, getSalesPoints } = useApi();
    const { user } = firebaseCredential();

    const pending = ref(false);
    const errorMessage = ref(null);

    const territories = ref(null);
    const formTypes = ref(null);
    const selectedTerritoryId = ref(null);
    const selectedFormTypeId = ref(null);

    const activeForm = ref(null);
    const salesPoints = ref(null);
    const pendingPoints = ref(false);
    const gpsAccuracy = ref(null);

    const geolocationError = (reason) => {
      if (reason.code == 1) {
        errorMessage.value =
          "Не удалось получить геолокацию, потому что у страницы не было на это разрешения.";
      } else {
        errorMessage.value = reason.message;
      }
    };

    const handleOnMounted = async () => {
      analytics.logEvent("home-page-loaded", { userId: user.value.uid });
      pending.value = true;
      Promise.all([getTerritories(), getFormsTypes()]).then(
        (values) => {
          // check territory
          territories.value = values[0].result;
          selectedTerritoryId.value = null;
          if (store.getters.territoryId != null) {
            for (let i = 0; i < territories.value.length; i++) {
              if (territories.value[i].id == store.getters.territoryId) {
                selectedTerritoryId.value = territories.value[i].id;
                break;
              }
            }
          }
          if (selectedTerritoryId.value == null) {
            selectedTerritoryId.value = territories.value[0].id;
            store.dispatch("setTerritoryId", selectedTerritoryId.value);
          }
          // check form type
          formTypes.value = values[1].result;
          if (formTypes.value.length > 0) {
            selectedFormTypeId.value = null;
            if (store.getters.formTypeId != null) {
              for (let i = 0; i < formTypes.value.length; i++) {
                if (formTypes.value[i].id == store.getters.formTypeId) {
                  selectedFormTypeId.value = formTypes.value[i].id;
                  break;
                }
              }
            }
            if (selectedFormTypeId.value == null) {
              selectedFormTypeId.value = formTypes.value[0].id;
              store.dispatch("setFormTypeId", selectedFormTypeId.value);
            }
          }
          pending.value = false;
        },
        (reason) => {
          errorMessage.value = reason.message;
          pending.value = false;
        }
      );
    };
    const updateActiveForm = async () => {
      let formIsStarted = await store.dispatch("formIsStarted");
      if (formIsStarted) {
        let answers = store.getters.answers;
        activeForm.value = {
          title: !answers.sales_point_title
            ? "<без названия>"
            : answers.sales_point_title,
          address: !answers.sales_point_address
            ? "<без адреса>"
            : answers.sales_point_address,
        };
      } else {
        activeForm.value = null;
      }
    };

    const handleSelectTerritory = () => {
      store.dispatch("setTerritoryId", selectedTerritoryId.value);
    };
    const handleSelectFormType = () => {
      store.dispatch("setFormTypeId", selectedFormTypeId.value);
    };
    const handleSalesPointsNearby = async () => {
      analytics.logEvent("search-sales-points", { userId: user.value.uid });
      salesPoints.value = null;
      gpsAccuracy.value = null;
      errorMessage.value = null;
      if (selectedFormTypeId.value == null) {
        errorMessage.value = "Выберете тип анкеты!";
      } else {
        pendingPoints.value = true;
        navigator.geolocation.getCurrentPosition(
          (position) => {
            getSalesPoints(
              position.coords.latitude,
              position.coords.longitude
            ).then(
              (res) => {
                salesPoints.value = res.result;
                gpsAccuracy.value = position.coords.accuracy;
                pendingPoints.value = false;
              },
              (reason) => {
                errorMessage.value = reason.message;
                pendingPoints.value = false;
              }
            );
          },
          (reason) => {
            geolocationError(reason);
            pendingPoints.value = false;
          },
          { enableHighAccuracy: true, maximumAge: 0 }
        );
      }
    };
    const handleNewForm = async (point) => {
      errorMessage.value = null;
      let formIsStarted = await store.dispatch("formIsStarted");
      if (formIsStarted === false) {
        // get location
        await store.dispatch("cancelForm");
        navigator.geolocation.getCurrentPosition(
          (position) => {
            store.dispatch("startForm", {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
            if (point != null) {
              for (let key in point.preparedAnswers) {
                store.dispatch("saveAnswer", {
                  qid: key,
                  value: point.preparedAnswers[key],
                });
              }
            }
            router.push({ name: "AuditForm" });
          },
          (reason) => {
            geolocationError(reason);
          },
          { enableHighAccuracy: true, maximumAge: 0 }
        );
      } else {
        errorMessage.value = "Ошибка создания анкеты! Обновите страницу.";
      }
    };
    const handleContinueForm = async () => {
      router.push({ name: "AuditForm" });
    };
    const handleCancelForm = async () => {
      if (
        confirm(
          "Все данные не отправленной анкеты будут удалены.\nПродолжить?"
        ) === true
      ) {
        await store.dispatch("cancelForm");
        activeForm.value = null;
      }
    };
    return {
      pending,
      errorMessage,
      territories,
      formTypes,
      selectedTerritoryId,
      selectedFormTypeId,
      activeForm,
      salesPoints,
      pendingPoints,
      gpsAccuracy,
      handleOnMounted,
      handleSelectTerritory,
      handleSelectFormType,
      updateActiveForm,
      handleSalesPointsNearby,
      handleNewForm,
      handleContinueForm,
      handleCancelForm,
    };
  },
  mounted() {
    this.handleOnMounted();
    this.updateActiveForm();
    window.addEventListener("focus", this.updateActiveForm);
    window.addEventListener("blur", this.updateActiveForm);
  },
  beforeUnmount() {
    window.removeEventListener("focus", this.updateActiveForm);
    window.removeEventListener("blur", this.updateActiveForm);
  },
};
</script>

<style>
.point-code-label {
  margin-left: 6px;
  margin-right: 4px;
  color: #818181;
}
.point-code {
  color: #818181;
}
.home-point-disabled {
  font-weight: 700;
  color: #a52a2a;
  margin-right: 6px;
}
</style>
