import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
import "firebase/functions";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyD6hlDVwHCLxhnsLiqBeQslROPwMWu9sm4",
  authDomain: "auditapp-236ed.firebaseapp.com",
  projectId: "auditapp-236ed",
  storageBucket: "auditapp-236ed.appspot.com",
  messagingSenderId: "924079860571",
  appId: "1:924079860571:web:a6ae06aa4e8e49ea52c87e",
  measurementId: "G-L8S88PT6WT",
};

const functionsRegion = "europe-west3"; //Frankfurt

// init firebase
firebase.initializeApp(firebaseConfig);

// init services
const projectFirestore = firebase.firestore();
const projectStorage = firebase.storage();
const projectAuth = firebase.auth();
const projectFunctions = firebase.app().functions(functionsRegion);

const analytics = firebase.analytics();

// timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export {
  projectFirestore,
  projectStorage,
  projectAuth,
  projectFunctions,
  analytics,
  timestamp,
};
