import { createStore } from "vuex";
import { v4 as uuidv4 } from "uuid";

import { projectFunctions } from "@/firebase/config";

const toLocalStorage = (name, value) => {
  if (value == null) {
    localStorage.removeItem(name);
  } else {
    localStorage.setItem(name, JSON.stringify(value));
  }
};
const fromLocalStorage = (name) => {
  try {
    return JSON.parse(localStorage.getItem(name));
  } catch (error) {
    return null;
  }
};
const deletePhotos = async (formUUID) => {
  const deleteAllPhotosInForm = projectFunctions.httpsCallable(
    "deleteAllPhotosInForm"
  );
  return await deleteAllPhotosInForm({ formUUID: formUUID });
};

export default createStore({
  state: {
    territoryId: null,
    formTypeId: null,
    formUUID: null,
    geoStart: null,
    answers: {},
  },
  getters: {
    territoryId(state) {
      return state.territoryId;
    },
    formTypeId(state) {
      return state.formTypeId;
    },
    formUUID(state) {
      return state.formUUID;
    },
    geoStart(state) {
      return state.geoStart;
    },
    answers(state) {
      return state.answers;
    },
  },
  mutations: {
    territoryId(state, value) {
      state.territoryId = value;
    },
    formTypeId(state, value) {
      state.formTypeId = value;
    },
    formUUID(state, value) {
      state.formUUID = value;
    },
    geoStart(state, value) {
      state.geoStart = value;
    },
    saveAnswer(state, answer) {
      state.answers[answer.qid] = answer.value;
    },
    resetAnswers(state) {
      state.answers = {};
    },
  },
  actions: {
    initialiseStore({ commit }) {
      // город
      let territoryId = fromLocalStorage("territoryId");
      commit("territoryId", territoryId);
      // тип анкеты
      let formTypeId = fromLocalStorage("formTypeId");
      commit("formTypeId", formTypeId);
      // текущая анкета
      let formUUID = fromLocalStorage("formUUID");
      let geoStart = fromLocalStorage("geoStart");
      if (formUUID != null && geoStart != null) {
        commit("formUUID", formUUID);
        commit("geoStart", geoStart);
        let answers = fromLocalStorage("answers");
        if (answers != null) {
          for (let key in answers) {
            commit("saveAnswer", { qid: key, value: answers[key] });
          }
        }
      } else {
        toLocalStorage("formUUID", null);
        toLocalStorage("geoStart", null);
        toLocalStorage("answers", null);
      }
    },
    setTerritoryId({ commit }, value) {
      commit("territoryId", value);
      toLocalStorage("territoryId", value);
    },
    setFormTypeId({ commit }, value) {
      commit("formTypeId", value);
      toLocalStorage("formTypeId", value);
    },
    saveAnswer({ commit }, answer) {
      commit("saveAnswer", answer);
      toLocalStorage("answers", this.getters.answers);
    },
    startForm({ commit }, geoStart) {
      let newUUID = uuidv4();
      commit("formUUID", newUUID);
      toLocalStorage("formUUID", newUUID);
      commit("geoStart", geoStart);
      toLocalStorage("geoStart", geoStart);
    },
    closeForm({ commit }) {
      commit("formUUID", null);
      toLocalStorage("formUUID", null);
      commit("geoStart", null);
      toLocalStorage("geoStart", null);
      commit("resetAnswers");
      toLocalStorage("answers", null);
    },
    cancelForm({ commit }) {
      // del uploded photos
      if (this.getters.formUUID) {
        deletePhotos(this.getters.formUUID);
      }
      // del answers
      commit("formUUID", null);
      toLocalStorage("formUUID", null);
      commit("geoStart", null);
      toLocalStorage("geoStart", null);
      commit("resetAnswers");
      toLocalStorage("answers", null);
    },
    formIsStarted({ commit }) {
      let formUUID = fromLocalStorage("formUUID");
      let geoStart = fromLocalStorage("geoStart");
      if (!(!formUUID && !geoStart)) {
        commit("formUUID", formUUID);
        commit("geoStart", geoStart);
        return true;
      }
      return false;
    },
  },
  modules: {},
});
