// import { ref } from 'vue';
import { projectAuth } from "@/firebase/config";

// refs

// logout function
const logout = async () => {
  try {
    // errorLogout.value = null;
    await projectAuth.signOut();
  } catch (error) {
    console.log("logout error", error.message);
  }
};

const useLogout = () => {
  return { logout };
};

export default useLogout;
