<template>
  <Navbar />
  <div class="content">
    <router-view />
  </div>
  <Footer />
</template>

<script>
import { useStore } from "vuex";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: { Navbar, Footer },
  beforeCreate() {
    const store = useStore();
    store.dispatch("initialiseStore");
  },
};
</script>
