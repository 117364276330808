import { ref } from "vue";
import { projectAuth } from "@/firebase/config";

// refs
const user = ref(projectAuth.currentUser);

// auth changes
projectAuth.onAuthStateChanged((_user) => {
  user.value = _user;
});

const firebaseIdToken = async () => {
  if (user.value != null) {
    return await user.value.getIdToken();
  }
  return null;
};

const firebaseCredential = () => {
  return { user, firebaseIdToken };
};

export default firebaseCredential;
