import axios from "axios";
import firebaseCredential from "@/composables/firebaseCredential";

const hostname = "https://do.dataforce.kz";
// const hostname = "http://localhost:5000";
const api = "forms/v2";

const firebaseCustomToken = async (username, password) => {
  let response = await axios.post(
    `${hostname}/token/`,
    {},
    {
      auth: {
        username: username,
        password: password,
      },
    }
  );
  return response.data.result.custom_token;
};

const apiRequest = async (url, requestBody) => {
  let { firebaseIdToken } = firebaseCredential();
  let idToken = await firebaseIdToken();
  let response = await axios.post(url, requestBody, {
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  });
  return response.data;
};

const getTerritories = async () => {
  let data = await apiRequest(`${hostname}/${api}/territories/`, {});
  return data;
};
const getFormsTypes = async () => {
  let data = await apiRequest(`${hostname}/${api}/forms_types/`, {});
  return data;
};
const getSalesPoints = async (lat, lon) => {
  let data = await apiRequest(`${hostname}/${api}/sales_points/`, {
    latitude: lat,
    longitude: lon,
  });
  return data;
};
const buildForm = async (formTypeId) => {
  let data = await apiRequest(
    `${hostname}/${api}/build_form/${formTypeId}`,
    {}
  );
  return data;
};
const submitAnswers = async (
  formTypeId,
  formUUID,
  territoryId,
  geoStart,
  geoEnd,
  answers
) => {
  let data = await apiRequest(
    `${hostname}/${api}/submit_answers/${formTypeId}/${formUUID}`,
    {
      answers: answers,
      territoryId: territoryId,
      geoStart: geoStart,
      geoEnd: geoEnd,
    }
  );
  return data;
};
const getStatistic = async () => {
  let data = await apiRequest(`${hostname}/${api}/users_statistics/`, {});
  return data;
};

const useApi = () => {
  return {
    firebaseCustomToken,
    getTerritories,
    getFormsTypes,
    getSalesPoints,
    buildForm,
    submitAnswers,
    getStatistic,
  };
};

export default useApi;
