<template>
  <div class="navbar">
    <nav>
      <div class="navbar-header">
        <div class="navbar-header-logo">
          <h3>
            <router-link to="/">Data Cloud</router-link>
          </h3>
        </div>
        <div class="navbar-user" v-if="user">
          <div class="navbar-user-username">{{ user.displayName }}</div>
          <div class="navbar-user-logout">
            <span @click="handleLogout">Выход</span>
          </div>
        </div>
      </div>
      <div class="navbar-menu" v-if="user">
        <router-link class="navbar-menu-item" to="/">Главная</router-link>
        <router-link class="navbar-menu-item" to="/stats"
          >Статистика</router-link
        >
      </div>
    </nav>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import firebaseCredential from "@/composables/firebaseCredential";
import useLogout from "@/composables/useLogout";
export default {
  setup() {
    const router = useRouter();
    const { user } = firebaseCredential();
    const { logout } = useLogout();

    const handleLogout = async () => {
      await logout();
      router.push({ name: "Login" });
    };
    return { handleLogout, user };
  },
};
</script>

<style>
.navbar {
  max-width: var(--page_width);
  margin: 0 auto;
  margin-bottom: 8px;
  padding: 12px;
  box-shadow: 2px 2px 2px #061d474d;
  background: -moz-linear-gradient(145deg, #4698cee6 0%, #0e598fe6 100%);
  background: -webkit-linear-gradient(145deg, #4698cee6 0%, #0e598fe6 100%);
  background: linear-gradient(145deg, #4698cee6 0%, #0e598fe6 100%);
  display: flex;
  flex-direction: column;
}
.navbar * {
  color: white;
}
.navbar-header {
  display: flex;
  padding-top: 6px;
  padding-bottom: 6px;
}
.navbar-user-username {
  text-align: end;
}
.navbar-header-logo {
  margin: auto 0;
}
.navbar-user {
  margin-left: auto;
  text-align: end;
}

.navbar-menu {
  display: flex;
  min-height: 30px;
  border-top: 1px solid #ffffff;
}
.navbar-menu-item {
  border-radius: 3px;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
  font-weight: 700;
  padding: 6px;
}
.navbar-menu-item:hover {
  background: #020f2799;
  color: white;
}
.navbar-user-logout {
  cursor: pointer;
  font-size: smaller;
  text-decoration: none;
}
.navbar-user-logout span {
  border-bottom: 1px dotted var(--primary);
  margin-left: auto;
}

@media screen and (max-width: 320px) {
  .navbar-user-username {
    font-size: smaller;
  }
}
</style>
